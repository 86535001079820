<template>
  <div>
    <b-row class="flex-column">
      <b-col>
        <h2 class="font-weight-bold text-dark">
          Checkout
        </h2>
        <span class="font-weight-bolder mr-2">
          {{ $t('updatedPrices') }} {{ new Date().getDate() }}/{{
            new Date().getMonth() + 1
          }}/{{ new Date().getFullYear() }}
        </span>
        <b-badge
          class="mr-1"
          variant="info"
        >
          {{ $t('validOnly24hr') }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-badge
          class="mr-1"
          :variant="$helpers.quoteStatusColor(quote.quote_status)"
        >
          {{ $helpers.quoteStatusName(quote.quote_status) }}
        </b-badge>
        <b-badge :variant="$helpers.paymentStatusColor(quote.payment_status)">
          {{ $helpers.paymentStatusName(quote.payment_status) }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <checkout
          :quote="quote"
          :project="project"
          :items="quote.quote_items_attributes"
          :address="quote.address"
          :tax-info="project.tax_information || {}"
          :refetch="fetchData"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Checkout from '@/components/invoice/Checkout.vue'

export default {
  components: {
    Checkout,
  },

  data() {
    return {
      items: [
        {
          folio: '91716',
          address: 'Depotivo Cumbres',
          municipality: 'Monterrey, N.L.',
          postal_code: '67110',
          date: '06 Ago 2021',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('products', ['products']),
    ...mapGetters('quotes', ['quote']),
    ...mapGetters('projects', ['project']),
    ...mapGetters('auth', ['currentUser']), // Acceso al usuario actual
  },

  created() {
    this.fetchData()
  },

  methods: {
    ...mapActions('quotes', ['fetchQuote', 'getQuotePayments']),
    ...mapActions('projects', ['fetchProject']),

    fetchData() {
      const that = this
      this.fetchQuote(this.$route.params.quoteId).then(response => {
        const suppliers = []
        const items = response.quote_items_attributes.map(item => {
          // handling null suppliers (not found)
          if (item.selected_supplier_branch_id === null) {
            return {
              ...item,
              supplier: 'No hay proveedor',
            }
          }

          if (!suppliers.includes(item.selected_supplier_branch_id)) {
            suppliers.push(item.selected_supplier_branch_id)
          }

          // Modificamos la lógica de supplier para considerar openQuotation
          return {
            ...item,
            supplier: (() => {
              if (this.currentUser.customer && this.currentUser.customer.openQuotation) {
                // Si openQuotation es true, muestra el nombre real del proveedor
                return item.selected_supplier_branch_name
              }

              if (
                ['auctioneer', 'supplier_admin'].includes(
                  this.currentUser.role_name,
                )
              ) {
                // Roles específicos aún pueden ver el nombre real
                return item.selected_supplier_branch_name
              }

              if (
                this.currentUser.role_name === 'admin'
                && this.currentUser.customer
                && !this.currentUser.customer.openQuotation
              ) {
                // Si el usuario es administrador pero openQuotation es falso, muestra un nombre genérico
                return `proveedor ${
                  suppliers.findIndex(
                    supplierId => supplierId === item.selected_supplier_branch_id,
                  ) + 1
                }`
              }

              // Si ninguna de las condiciones anteriores se cumple, muestra un nombre genérico
              return `proveedor ${
                suppliers.findIndex(
                  supplierId => supplierId === item.selected_supplier_branch_id,
                ) + 1
              }`
            })(),

          }
        })
        response.quote_items_attributes = items
        that.fetchProject(response.project_id)
      })
      this.getQuotePayments({ quoteId: this.$route.params.quoteId })
    },
  },
}
</script>

<style></style>
